import React from "react"

import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const Page = () => (
  <Layout>
    <Seo title="Problemer ved kjøp" />
    <div className="longtext">
      <h2>Problemer ved kjøp</h2>
      <h3>Problem</h3>
      <p>
        Du får feilmeldingen{" "}
        <b>Kjøpsløsningen får ikke kontakt med App Store akkurat nå</b>.
      </p>

      <h3>Løsning:</h3>
      <p>
        Vanligvis skyldes dette ustabilitet hos enten kjøpsløsningen vi
        benytter, eller hos App Store, slik at Mente ikke får ordentlig beskjed
        om hvordan det gikk med kjøpet ditt.
      </p>

      <p>
        <b>Slik fikser vi det:</b>
      </p>

      <p>
        1. Prøv først å trykke Gjenopprett kjøp nederst i kjøpsbildet. Dette vil
        kontakte App Store/kjøpsløsningen på nytt og vanligvis vil dette løse
        problemet:
      </p>
      <StaticImage src="../../images/restore.png" alt="Restore" />

      <p>
        2. Dersom det ikke hjelper. Se på feilmeldingen du får fra{" "}
        <b>Gjenopprett kjøp</b>:
      </p>

      <p>
        2a: Dersom den sier{" "}
        <b>Fant ingen tidligere kjøp som fortsatt er gyldige.</b>, så betyr det
        at kjøpsløsningen fikk kontakt med App Store igjen, men at kjøpet ditt
        ikke gikk gjennom i første omgang. Løsningen er derfor å trykke{" "}
        <b>Start abonnement</b> på nytt.
      </p>

      <p>
        2b: Feilmeldingen sier noe annet. Vent 5 minutter og prøv så{" "}
        <b>Gjenopprett kjøp</b> igjen. Dersom problemet fortsetter, ta kontakt
        med oss på hello@mente.app, så vil vi hjelpe deg.
      </p>
    </div>
  </Layout>
)

export default Page
